/**
 * jQuery toast plugin para iCconstruye 2017
 */
.jq-toast-wrap { display: block; position: fixed; width: 90%;  pointer-events: none !important; margin: 0; padding: 0; letter-spacing: normal; z-index: 9000 !important;}
.jq-toast-wrap * { margin: 0; padding: 0; }

.jq-toast-wrap.bottom-left { bottom: 20px; left: 20px; }
.jq-toast-wrap.bottom-right { bottom: 20px; right: 40px; }
.jq-toast-wrap.top-left { top: 20px; left: 20px; }
.jq-toast-wrap.top-right { top: 20px; right: 40px; }

.jq-toast-single { display: block; width: 100%; padding: 10px; margin: 0px 0px 5px; border-radius: 4px; font-size: 12px; font-family: arial, sans-serif; line-height: 17px; position: relative;  pointer-events: all !important; background-color: #444444; color: white;  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;}

.jq-toast-single h2 { font-family: arial, sans-serif; font-size: 14px; margin: 0px 0px 7px; background: none; color: inherit; line-height: inherit; letter-spacing: normal; }
.jq-toast-single a { color: #eee; text-decoration: none; font-weight: bold; border-bottom: 1px solid white; padding-bottom: 3px; font-size: 12px; }

.jq-toast-single ul { margin: 0px 0px 0px 15px; background: none; padding:0px; }
.jq-toast-single ul li { list-style-type: disc !important; line-height: 17px; background: none; margin: 0; padding: 0; letter-spacing: normal; }

.close-jq-toast-single { position: absolute; top: 3px; right: 7px; font-size: 14px; cursor: pointer; }

.jq-toast-loader { display: block; position: absolute; top: -2px; height: 5px; width: 0%; left: 0; border-radius: 5px; background: red; }
.jq-toast-loaded { width: 100%; }
.jq-has-icon { 
	padding: 10px 10px 10px 50px;
	background-repeat: no-repeat;
	background-position: 10px;
}
.jq-icon-info {
	background-image: url(/assets/img/info.png);
    color: #31708f;
    background-color: #d9edf7;
    border:1px solid #bce8f1;
}
.jq-icon-warning {
	background-image: url(/assets/img/warning.png);
    color: #8a6d3b;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
 }
.jq-icon-error {
	background-image: url(/assets/img/error.png);
	color: #a94442;
    background-color: #f2dede;
    border:1px solid #ebccd1;
}
.jq-icon-success {
	background-image: url(/assets/img/success.png);
    color: #3c763d;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
 }